import React from 'react'
import moment from 'moment'
const LabelValue = Loader.loadBaseComponent('LabelValue')


const { vehicleColor, plateColor } = Dict.map
const HeaderVehicle = ({ item = {} }) => {
  /*机动车颜色vehicleColor 车牌颜色plateColor*/
  let vehicleC = '', plateC = '';
  const vehicleColorItem = vehicleColor.find(v => item.tags && item.tags.indexOf(v.value + '') !== -1) || {};
  vehicleC = vehicleColorItem.text || '';
  const plateColorItem = plateColor.find(v => item.tags && item.tags.indexOf(v.value + '') !== -1) || {};
  plateC = plateColorItem.text || '';
  const plateNo = item.plateNo === "无车牌" ? '-' : item.plateNo;
  return (
    <div className='box-container'>
      <div className="header">
        <LabelValue className="item" label="车牌颜色" value={plateC || '-'} />
        <LabelValue className="item" label="机动车颜色" value={vehicleC || '-'} />
        <LabelValue className="item" label="车牌号码" value={plateNo || '-'} />
      </div>
      <div className="header">
        <LabelValue className="item" label="抓拍时间" value={moment(parseInt(item.captureTime, 10)).format(Shared.format.dataTime) || '-'} />
        <LabelValue className="item" label="抓拍地点" value={item.deviceName ? item.deviceName : '-'} />
        <i className="item"></i>
      </div>
    </div>
  )
}
export default HeaderVehicle